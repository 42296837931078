import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

/**
 * check if user is logged in, if yes, return true, else redirect to login page.
 */
@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
      private _router: Router,
      private _authService: AuthService
  ) {}

  canActivate(
      route: ActivatedRouteSnapshot,
      state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    // Logged in so return true
    if (this._authService.isAuthenticated()) {
    return true;
    }

    // Not logged in so redirect to login page
    this._router.navigate([`/auth/login`]);
    return false;
  }
}
