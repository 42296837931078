import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, catchError, map, Observable, throwError } from "rxjs";
import { environment } from '../../../environments/environment';
import { LoginRequestModel } from "../models/login-request.model";
import { AuthModel, AuthResponseModel } from "../models/auth-response.model";
import { Router } from "@angular/router";
export const SIMPLELYTICS_STORE = 'simplelytics_store';

@Injectable()
export class AuthService {
    private apiUrl = environment.api.url;

    private _authStore: BehaviorSubject<AuthModel | null> = new BehaviorSubject<AuthModel | null>(null);
    public readonly authStore: Observable<AuthModel | null> = this._authStore.asObservable();

    constructor(private http: HttpClient, private _router: Router) {
        this.loadAuthStore();
    }

    loadAuthStore(): void {
        let store = localStorage.getItem(SIMPLELYTICS_STORE);
        if (!store) return;
        try {
            const clientStore = JSON.parse(store);
            this._authStore.next(clientStore);
        } catch (err) {
            console.error(err);
        }
    }

    isAuthenticated(): boolean {
        const store = this._authStore.value;
        if (!store) return false;
        return !!store.token;
    }

    getToken(): string | null {
        const store = this._authStore.value;
        if (!store) return null;
        return store.token;
    }

    signIn(formData: LoginRequestModel): Observable<AuthResponseModel> {
        return this.http.post<AuthResponseModel>(`${this.apiUrl}token`, formData).pipe(
            catchError(error => throwError(error)),
            map(response => {
                this.updateAuthStore(response.data);
                return response;
            })
        );
    }

    updateAuthStore(data: AuthModel): void {
        this._authStore.next(data);
        localStorage.setItem(SIMPLELYTICS_STORE, JSON.stringify(data));
    }

    logout() {
        // Clean up auth store
        this._authStore.next(null);
        // reset localstorage
        this.resetLocalStorage();
        this._router.navigate(['/auth/login']);
        return true;
    }

    resetLocalStorage() {
        localStorage.clear();

        // delete token from cookies
        document.cookie = `token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    }

    clearAuthStore() {
        this._authStore.next(null);
        localStorage.removeItem(SIMPLELYTICS_STORE);
    }

    requestPasswordToken(formData: any): Observable<any> {
        return this.http.post<any>(`${this.apiUrl}password/email`, formData).pipe(
            catchError(error => throwError(error)),
            map(response => {
                return response;
            })
        )
    }

    resetPasswordWithToken(formData: any): Observable<any> {
        return this.http.post<any>(`${this.apiUrl}password/reset`, formData).pipe(
            catchError(error => throwError(error)),
            map(response => {
                return response;
            })
        )
    }
}
