import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from "rxjs";
import { AuthService } from "../shared/services/auth.service";
import { ActivatedRoute, Router } from "@angular/router";
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor {
    private AUTH_HEADER = 'Authorization';

    constructor(
        private authService: AuthService,
        private router: Router,
        private route: ActivatedRoute,
        private _authService: AuthService
    ) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        req = req.clone({
            headers: req.headers.set(this.AUTH_HEADER, `Bearer ${this.authService.getToken()}`)
        });

        return next.handle(req).pipe(
            catchError(error => {
                return this.handleResponseError(error, req, next);
            })
        );
    }

    /**
     * 401: token invalid, delete token, route back to home page
     */
    private handleResponseError(error: any, request: HttpRequest<any>, next?: HttpHandler): Observable<any> {
        const apiUrlRegex = new RegExp(environment.api.url);

        if (request.url.match(apiUrlRegex) && error.status === 401) {
            localStorage.clear();
            sessionStorage.clear();
            this._authService.clearAuthStore();
            return throwError(() => error);
        }

        // return if no status code match
        return throwError(error);
    }
}
