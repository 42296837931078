import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_BASE_HREF } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { MatNativeDateModule } from '@angular/material/core';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatListModule } from '@angular/material/list';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTabsModule } from '@angular/material/tabs';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

import { AppComponent } from './app.component';

import { SidebarModule } from './sidebar/sidebar.module';
import { FooterModule } from './shared/footer/footer.module';
import { NavbarModule } from './shared/navbar/navbar.module';
import { FixedpluginModule } from './shared/fixedplugin/fixedplugin.module';
import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';
import { AuthService } from "./shared/services/auth.service";
import { AuthGuard } from "./shared/guards/auth.guard";
import { LoginGuard } from "./shared/guards/login.guard";
import { CampaignService } from "./shared/services/campaign.service";
import { AuthInterceptor } from "./_interceptors/auth.interceptor";

@NgModule({
    exports: [
        MatAutocompleteModule,
        MatButtonToggleModule,
        MatCardModule,
        MatChipsModule,
        MatCheckboxModule,
        MatStepperModule,
        MatDialogModule,
        MatExpansionModule,
        MatGridListModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatMenuModule,
        MatPaginatorModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatSelectModule,
        NgxMatSelectSearchModule,
        MatDatepickerModule,
        MatButtonModule,
        MatSidenavModule,
        MatSliderModule,
        MatSlideToggleModule,
        MatSnackBarModule,
        MatSortModule,
        MatTableModule,
        MatTabsModule,
        MatToolbarModule,
        MatTooltipModule,
        MatNativeDateModule
    ]
})
export class MaterialModule {}

@NgModule({
    imports: [
        CommonModule,
        BrowserAnimationsModule,
        FormsModule,
        RouterModule.forRoot([
            {
                path: '',
                redirectTo: 'auth/login',
                pathMatch: 'full',
            },
            {
                path: '',
                canActivate: [AuthGuard],
                component: AdminLayoutComponent,
                children: [
                    {
                        path: 'dashboard',
                        loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)
                    },
                    {
                        path: 'report-status',
                        loadChildren: () => import('./status-table/status-table.module').then(m => m.StatusTableModule)
                    },
                    // {
                    //     path: 'components',
                    //     loadChildren: () => import('./components/components.module').then(m => m.ComponentsModule)
                    // },
                    // {
                    //     path: 'forms',
                    //     loadChildren: () => import('./forms/forms.module').then(m => m.Forms)
                    // },
                    // {
                    //     path: 'tables',
                    //     loadChildren: () => import('./tables/tables.module').then(m => m.TablesModule)
                    // },
                    // {
                    //     path: 'maps',
                    //     loadChildren: () => import('./maps/maps.module').then(m => m.MapsModule)
                    // },
                    // {
                    //     path: 'widgets',
                    //     loadChildren: () => import('./widgets/widgets.module').then(m => m.WidgetsModule)
                    // },
                    // {
                    //     path: 'charts',
                    //     loadChildren: () => import('./charts/charts.module').then(m => m.ChartsModule)
                    // },
                    // {
                    //     path: 'calendar',
                    //     loadChildren: () => import('./calendar/calendar.module').then(m => m.CalendarModule)
                    // },
                    {
                        path: 'user',
                        loadChildren: () => import('./user/user.module').then(m => m.UserModule)
                    },
                    {
                        path: 'brand',
                        loadChildren: () => import('./brand/brand.module').then(m => m.BrandModule)
                    },
                    {
                        path: 'company',
                        loadChildren: () => import('./company/company.module').then(m => m.CompanyModule)
                    },
                    // {
                    //     path: '',
                    //     loadChildren: () => import('./userpage/user.module').then(m => m.UserModule)
                    // },
                    // {
                    //     path: '',
                    //     loadChildren: () => import('./timeline/timeline.module').then(m => m.TimelineModule)
                    // },
                ]
            },
            {
                path: 'auth',
                canActivate: [LoginGuard],
                component: AuthLayoutComponent,
                children: [{
                    path: '',
                    loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule)
                }]
            },
            {path: '**', redirectTo: '/dashboard', pathMatch: 'full'}
        ]),
        HttpClientModule,
        MaterialModule,
        SidebarModule,
        NavbarModule,
        FooterModule,
        FixedpluginModule
    ],
    declarations: [
        AppComponent,
        AdminLayoutComponent,
        AuthLayoutComponent
    ],
    providers: [
        AuthGuard,
        AuthService,
        LoginGuard,
        CampaignService,
        MatNativeDateModule,
        {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
