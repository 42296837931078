import {Injectable} from '@angular/core';
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {catchError, map, Observable, throwError} from "rxjs";

@Injectable()
export class CampaignService {
    private apiUrl = environment.api.url;

    constructor(private http: HttpClient) {
    }

    getClientList(): Observable<any> {
        return this.http.get<any>(`${this.apiUrl}admin/clients`).pipe(
            catchError(error => throwError(error)),
            map(response => {
                return response;
            })
        )
    }

    getAllClients(): Observable<any> {
        return this.http.get<any>(`${this.apiUrl}admin/clients/all`).pipe(
            catchError(error => throwError(error)),
            map(response => {
                return response;
            })
        )
    }

    getIndustryList(): Observable<any> {
        return this.http.get<any>(`${this.apiUrl}admin/industry`).pipe(
            catchError(error => throwError(error)),
            map(response => {
                return response;
            })
        )
    }

    getSalesOrdersByClient(data?: any): Observable<any> {
        let url;
        if (data) {
            url = `${this.apiUrl}admin/sales-order?company_id=${data.company_id}`;
        } else {
            url = `${this.apiUrl}admin/sales-order`
        }
        return this.http.get<any>(url).pipe(
            catchError(error => throwError(error)),
            map(response => {
                return response;
            })
        )
    }

    getReportStatus(data: any): Observable<any> {
        return this.http.get<any>(`${this.apiUrl}admin/report-status?order=${data.order}`).pipe(
            catchError(error => throwError(error)),
            map(response => {
                return response;
            })
        )
    }

    getAllReportStatus(): Observable<any> {
        return this.http.get<any>(`${this.apiUrl}admin/report-status-all`).pipe(
            catchError(error => throwError(error)),
            map(response => {
                return response;
            })
        )
    }

    generateSlide(formData: any): Observable<any> {
        return this.http.post<any>(`${this.apiUrl}admin/excel`, formData).pipe(
            catchError(error => throwError(error)),
            map(response => {
                return response;
            })
        )
    }
}
