<footer class="footer ">
  <div class="container-fluid">
    <nav class="pull-left">
<!--      <ul>-->
<!--        <li>-->
<!--          <a href="https://www.creative-tim.com">-->
<!--            Creative Tim-->
<!--          </a>-->
<!--        </li>-->
<!--        <li>-->
<!--          <a href="https://creative-tim.com/about-us">-->
<!--            About Us-->
<!--          </a>-->
<!--        </li>-->
<!--        <li>-->
<!--          <a href="http://blog.creative-tim.com">-->
<!--            Blog-->
<!--          </a>-->
<!--        </li>-->
<!--        <li>-->
<!--          <a href="https://www.creative-tim.com/license">-->
<!--            Licenses-->
<!--          </a>-->
<!--        </li>-->
<!--      </ul>-->
    </nav>
<!--    <div class="copyright pull-right">-->
<!--      &copy;-->
<!--      {{year | date: 'yyyy'}}, Powered by-->
<!--      <a href="https://www.appify.my" target="_blank">Appify Technologies</a>.-->
<!--    </div>-->
  </div>
</footer>
